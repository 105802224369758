import { Check } from 'lucide-react';
// import Button from '../ui/Button';

const Pricing = () => {
  const plans = [
    {
      title: "Discovery Session",
      price: "40",
      description: "Perfect for getting to know your coach and starting your wellness journey.",
      features: [
         "45-minute one-on-one session", 
        "Discuss your wellness goals and movement needs", 
        "Receive a home exercise video focused on one key movement ",
        "Personalized plan to get you started "

      ],
      note: "Ideal for clients new to coaching or who need direction before committing to more.",
      buttonColor: "bg-[#FFA500]" // Orange/Gold color
    },
    {
      title: "Personalized Movement Session",
      badge: "best value",
      class: "ring-2 ring-[#FF4D8D]",
      price: "65",
      description: "Perfect for building sustainable movement habits with personalized support.",
      features: [
        "45-minute one-on-one session",
        "Focus on your unique movement needs",
        "Receive a home exercise video after every session",
        "Perfect for improving movement mechanics and flexibility"
        
      ],
      note: "Best for clients seeking consistent progress and long-term results at a discounted rate.",
      buttonColor: "bg-[#FF1493]" // Pink color
    },
    {
      title: "Sustainable Movement Package",
      description: "Perfect for focusing on movement improvement with targeted guidance.",
      price: "325",
      features: [
        "1 Discovery Session",
        "5 Personalized Movement Sessions",
        "45-minute one-on-one sessions",
        "Home exercise videos after every session",
        "Progress tracking throughout the package"
        
      ],
      note: "Great for those looking to dive deeper into their movement practice without committing to a package.",
      buttonColor: "bg-[#FFA500]" // Orange/Gold color
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-[#FFF7E6] to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <p className="text-[#FF69B4] font-semibold uppercase tracking-wide">PRICING PLANS</p>
          <h2 className="text-4xl font-bold text-gray-900 mt-2">Invest in Your Wellness.</h2>
          <p className="text-xl text-gray-500 mt-4">Flexible options to support your unique path to wellness</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
          {plans.map((plan, index) => (
            <div key={index} className={`bg-white rounded-xl shadow-lg p-8 relative ${plan.class} flex flex-col h-full`}>
              {plan.badge && (
                <div className="absolute top-0 rounded-tr-xl right-0 bg-[#FF4D8D] text-white px-4 py-1 text-sm font-medium">{plan.badge}</div>
                
              )}
              
              <h3 className="text-3xl font-bold mb-4">{plan.title}</h3>
              <div className="m-3 flex items-baseline">
                <span className="text-4xl font-extrabold text-gray-700">${plan.price}</span>
                {/* <span className="ml-1 text-xl text-gray-500">/session</span> */}
              </div>
              <p className="text-gray-600 mb-6">{plan.description}</p>
              
              <div className="flex-grow">
                <ul className="space-y-4 mb-6">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start text-gray-700">
                      <Check className="h-5 w-5 text-[#FF4D8D] mr-1" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <p className="text-sm text-gray-600 mb-8">{plan.note}</p>
              </div>

              <div className="mt-auto">
                <button 
                  className={`w-full py-3 px-6 rounded-lg text-white font-semibold ${plan.buttonColor} hover:opacity-90 transition-opacity`}
                >
                  Get Started
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;