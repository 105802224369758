import { Heart, Users, Star, Trophy } from 'lucide-react';

const ClientJourney = () => {
  const steps = [
    {
      icon: Heart,
      class:"mt-6",
      title: "Share Your Story",
      description: "Tell us about your PT journey, goals, and what makes you uniquely you. We'll use this to find your perfect coach match.        ",
      image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1739453303/6_Jordan_u8fgdk.jpg"
    },
    {
      icon: Users,
      title: "Meet Your Coach",
      description: "Get matched with a certified coach who understands your needs and shares your enthusiasm for unconventional approaches.",
      image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1739453303/7_Jordan_bwfeql.jpg"
    },
    {
      icon: Star,
      class:"mt-6",
      title: "Personalized Sessions",
      description: "Enjoy tailored virtual sessions that blend traditional techniques with innovative movement practices.",
      image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1739453303/8_Jordan_g2kyzx.jpg"
    },
    {
      icon: Trophy,
      class:"mt-6",
      title: "Track Progress",
      description: "Celebrate your wins and track your progress with our intuitive tracking tools and regular check-ins.",
      image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1739453303/9_Jordan_tmychi.jpg"
    }
  ];

  return (
    <section id="for-clients" className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <p className="text-[#FF69B4] font-semibold uppercase tracking-wide">
            FOR CLIENTS
          </p>
          <h2 className="mt-2 text-4xl font-bold text-gray-900">
            Your Journey to Wellness
          </h2>
          <p className="mt-4 text-xl text-gray-500 max-w-2xl mx-auto">
            We make it easy to continue your wellness journey with expert guidance and support.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={step.title} className="relative">
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-24  -right-4 transform translate-x-1/2 z-10">
                  <svg className="w-6 h-6 text-[#FFD43B]" viewBox="0 0 24 24" fill="none">
                    <path d="M5 12h14m-7-7l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                
              )}
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-6">
                  <div className="inline-flex items-center justify-center w-12 h-12 bg-[#FF4D8D] rounded-xl mb-4">
                    <step.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                <img 
                  src={step.image}
                  alt={step.title}
                  className={`w-full h-48 object-cover ${step.class}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientJourney;