import  { useState, useEffect } from 'react';
import { Search, Filter, User, Calendar, } from 'lucide-react';
import Button from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import { toast } from 'react-hot-toast';

interface CoachApplication {
  id: string;
  applicant: {
    name: string;
    email: string;
    phone: string;
  };
  specialties: string[];
  experience: string;
  certifications: string[];
  status: 'pending' | 'approved' | 'rejected';
  submittedAt: string;
  reviewedAt?: string;
  reviewedBy?: string;
  reviewNotes?: string;
}

const CoachApplications = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState<CoachApplication[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        // Fetch coach applications from Supabase
        const { data, error } = await supabase
          .from('Coach')
          .select(`
            id,
            firstName,
            lastName,
            phone,
            applicationStatus,
            adminNotes,
            createdAt,
            hobbies,
            certifications,
            wellnessStory,
            User (
              email
            )
          `)
          .order('createdAt', { ascending: false });

        if (error) {
          throw error;
        }

        // Transform the data into our application format
        const transformedData: CoachApplication[] = data.map(coach => ({
          id: coach.id,
          applicant: {
            name: `${coach.firstName || ''} ${coach.lastName || ''}`.trim(),
            email: coach.User[0]?.email || '',
            phone: coach.phone || ''
          },
          specialties: coach.hobbies || [],
          experience: coach.wellnessStory?.substring(0, 100) + (coach.wellnessStory?.length > 100 ? '...' : '') || '',
          certifications: coach.certifications?.map((cert: any) => cert.name || cert) || [],
          status: coach.applicationStatus || 'pending',
          submittedAt: coach.createdAt,
          reviewNotes: coach.adminNotes
        }));

        setApplications(transformedData);
        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching coach applications:', error);
        setError(error.message || 'Failed to load applications');
        toast.error('Failed to load applications');
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  // Helper function to normalize status for comparison
  const normalizeStatus = (status: string): string => {
    return status.toLowerCase();
  };

  // Filter applications based on search query and status filter
  const filteredApplications = applications.filter(app => {
    const matchesSearch = 
      app.applicant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      app.applicant.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      app.certifications.some(cert => cert.toLowerCase().includes(searchQuery.toLowerCase())) ||
      app.specialties.some(specialty => specialty.toLowerCase().includes(searchQuery.toLowerCase()));
    
    const matchesStatus = statusFilter === 'all' || app.status === statusFilter;
    
    return matchesSearch && matchesStatus;
  });

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12 flex justify-center items-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading applications...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-4 text-red-600">Error</h1>
          <p className="text-gray-700">{error}</p>
          <Button 
            className="mt-6"
            onClick={() => window.location.reload()}
          >
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold">Coach Applications</h1>
          <p className="text-gray-600">Review and manage coach applications</p>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="bg-white rounded-xl shadow-lg p-4 mb-8">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex-1 flex items-center">
            <div className="relative flex-1 max-w-md">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search applications..."
                className="pl-10 w-full rounded-lg border-gray-300 focus:border-primary focus:ring-primary"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Button variant="secondary" className="ml-4">
              <Filter className="w-4 h-4 mr-2" />
              Filters
            </Button>
          </div>
          <div className="flex items-center gap-4">
            <select 
              className="rounded-lg border-gray-300 focus:border-primary focus:ring-primary"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
        </div>
      </div>

      {/* Applications Grid */}
      {filteredApplications.length === 0 ? (
        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
          <User className="w-12 h-12 text-gray-300 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900">No applications found</h3>
          <p className="mt-1 text-gray-500">
            {searchQuery || statusFilter !== 'all' 
              ? 'Try adjusting your filters or search query'
              : 'There are no coach applications yet'}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredApplications.map(application => (
            <div
              key={application.id}
              className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                    <User className="w-6 h-6 text-gray-500" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold">{application.applicant.name}</h3>
                    <p className="text-sm text-gray-500">{application.applicant.email}</p>
                  </div>
                </div>
                <span className={`px-3 py-1 rounded-full text-sm ${
                  normalizeStatus(application.status) === 'approved' ? 'bg-green-100 text-green-800' :
                  normalizeStatus(application.status) === 'rejected' ? 'bg-red-100 text-red-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {application.status.charAt(0).toUpperCase() + application.status.slice(1).toLowerCase()}
                </span>
              </div>

              <div className="space-y-4 mb-6">
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Specialties</h4>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {application.specialties.slice(0, 3).map(specialty => (
                      <span
                        key={specialty}
                        className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                      >
                        {specialty}
                      </span>
                    ))}
                    {application.specialties.length > 3 && (
                      <span className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-700">
                        +{application.specialties.length - 3} more
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-700">Certifications</h4>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {application.certifications.slice(0, 2).map(cert => (
                      <span
                        key={cert}
                        className="px-2 py-1 bg-primary/10 rounded-full text-sm text-primary"
                      >
                        {cert}
                      </span>
                    ))}
                    {application.certifications.length > 2 && (
                      <span className="px-2 py-1 bg-primary/10 rounded-full text-sm text-primary">
                        +{application.certifications.length - 2} more
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-700">Experience</h4>
                  <p className="text-sm text-gray-600 mt-1">{application.experience}</p>
                </div>

                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="w-4 h-4 mr-1" />
                  Submitted {new Date(application.submittedAt).toLocaleDateString()}
                </div>
              </div>

              <Button
                className="w-full"
                onClick={() => navigate(`/admin/applications/${application.id}`)}
              >
                Review Application
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CoachApplications;