import { useState, useEffect } from 'react';
import { ArrowLeft, Mail, User, Calendar, Download, Search } from 'lucide-react';
import Button from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import { toast } from 'react-hot-toast';

interface Subscription {
  id: string;
  email: string;
  createdAt: string;
  source?: string;
}

// Updated to match the actual database structure
interface Referral {
  id: string;
  referrerEmail: string;
  referredEmail: string;
  createdAt: string;
  updatedAt: string;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
  message?: string;
}

const SubscribersAndReferrals = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'subscriptions' | 'referrals'>('subscriptions');
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        if (activeTab === 'subscriptions') {
          const { data, error } = await supabase
            .from('EmailSubscription')
            .select('*')
            .order('createdAt', { ascending: false });

          if (error) throw error;
          setSubscriptions(data || []);
        } else {
          console.log('Fetching referrals...');
          
          const { data, error } = await supabase
            .from('Referral')
            .select('*')
            .order('createdAt', { ascending: false });

          if (error) throw error;
          console.log('Referral data:', data);
          
          // Ensure data matches our interface
          const transformedData: Referral[] = (data || []).map((ref: any) => ({
            id: ref.id,
            referrerEmail: ref.referrerEmail,
            referredEmail: ref.referredEmail,
            createdAt: ref.createdAt,
            updatedAt: ref.updatedAt,
            status: ref.status || 'PENDING',
            message: ref.message
          }));
          
          setReferrals(transformedData);
        }
      } catch (error: any) {
        console.error(`Error fetching ${activeTab}:`, error);
        setError(error.message || `Failed to load ${activeTab}`);
        toast.error(`Failed to load ${activeTab}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]);

  // Filter data based on search query
  const filteredSubscriptions = subscriptions.filter(sub => 
    sub.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (sub.source && sub.source.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredReferrals = referrals.filter(ref => 
    ref.referrerEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
    ref.referredEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (ref.message && ref.message.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Export data to CSV
  const exportToCSV = () => {
    const data = activeTab === 'subscriptions' ? subscriptions : referrals;
    if (data.length === 0) {
      toast.error('No data to export');
      return;
    }

    // Create CSV content
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(item => Object.values(item).join(','));
    const csvContent = [headers, ...rows].join('\n');

    // Create and download the file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${activeTab}_${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast.success(`${activeTab} exported successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <button 
          onClick={() => navigate('/admin')}
          className="flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Dashboard
        </button>
        <h1 className="text-2xl font-bold mt-4">Mailing List</h1>
        <p className="text-gray-600">View and manage email subscriptions and referrals</p>
      </div>

      {/* Search and Export */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
        <div className="relative w-full sm:w-96 mb-4 sm:mb-0">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <Button onClick={exportToCSV} className="w-full sm:w-auto">
          <Download className="mr-2 h-4 w-4" />
          Export to CSV
        </Button>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-8">
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'subscriptions'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('subscriptions')}
          >
            Email Subscriptions
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'referrals'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('referrals')}
          >
            Referrals
          </button>
        </div>
      </div>

      {/* Content */}
      {loading ? (
        <div className="text-center py-12">
          <div className="spinner"></div>
          <p className="mt-4 text-gray-600">Loading data...</p>
        </div>
      ) : error ? (
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-800">{error}</p>
          <Button onClick={() => setError(null)} className="mt-4" variant="secondary">
            Try Again
          </Button>
        </div>
      ) : activeTab === 'subscriptions' ? (
        // Subscriptions table
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {filteredSubscriptions.length === 0 ? (
            <div className="py-12 text-center">
              <Mail className="h-12 w-12 text-gray-300 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">No subscriptions found</h3>
              <p className="mt-1 text-gray-500">
                {searchQuery ? 'Try adjusting your search query' : 'There are no email subscriptions yet'}
              </p>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Source
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subscription Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSubscriptions.map((sub) => (
                  <tr key={sub.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Mail className="h-5 w-5 text-gray-400 mr-3" />
                        <a 
                          href={`mailto:${sub.email}`} 
                          className="text-primary hover:underline"
                        >
                          {sub.email}
                        </a>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      {sub.source || 'Website'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                        {new Date(sub.createdAt).toLocaleDateString()}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        // Referrals table - removed status and actions columns
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {filteredReferrals.length === 0 ? (
            <div className="py-12 text-center">
              <User className="h-12 w-12 text-gray-300 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">No referrals found</h3>
              <p className="mt-1 text-gray-500">
                {searchQuery ? 'Try adjusting your search query' : 'There are no referrals yet'}
              </p>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Referrer Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Referred Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Message
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredReferrals.map((ref) => (
                  <tr key={ref.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a 
                        href={`mailto:${ref.referrerEmail}`} 
                        className="text-primary hover:underline text-sm"
                      >
                        {ref.referrerEmail}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a 
                        href={`mailto:${ref.referredEmail}`} 
                        className="text-primary hover:underline"
                      >
                        {ref.referredEmail}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      {ref.message || 'No message'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                        {new Date(ref.createdAt).toLocaleDateString()}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscribersAndReferrals; 