import { Users, Calendar, Video } from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: Users,
      title: "Complete Quick Assessment",
      description: "Tell us about your journey, goals, and what makes you uniquely you. We'll use this to find your perfect coach match."
    },
    {
      icon: Calendar,
      title: "Get Matched & Schedule",
      description: "We'll pair you with a certified coach who understands your needs. Choose a time that works for your schedule."
    },
    {
      icon: Video,
      title: "Start Your Sessions",
      description: "Connect with your coach through our high-quality video platform. Begin your personalized wellness journey."
    }
  ];

  return (
    <section id="how-it-works" className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-base text-[#FF69B4] font-semibold tracking-wide uppercase">
            HOW IT WORKS
          </h2>
          <h3 className="mt-2 text-4xl font-bold text-gray-900">
            Your Journey to Better Movement
          </h3>
          <div className="mt-4  px-6 py-2 rounded-md">
            <p className=" text-xl font-bold text-gray-500">
              Three Simple Steps to Lasting Wellness.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={step.title} className="relative">
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-24 -right-4 transform translate-x-1/2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-[#FF4D8D]">
                    <path d="M5 12h14m-7-7l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              )}
              <div className="bg-white rounded-xl p-8 shadow-lg">
                <div className="inline-flex items-center justify-center w-16 h-16 bg-[#FF4D8D] rounded-2xl mb-6">
                  <step.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className=" text-xl italic text-gray-500">
            "Jordan took the survey, met their coach, and started weekly sessions. Soon, they felt stronger and more capable than ever before."
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 