import { api } from '../lib/api';

export interface EmailParams extends Record<string, unknown> {
  to_email: string;
  to_name: string;
  message: string;
  from_name: string;
}

interface ThankYouEmailParams {
  to_email: string;
  to_name?: string;
  message: string;
}

export const sendReferralEmail = async (params: EmailParams): Promise<void> => {
  try {
    await api.post('/api/email/send-referral', params);
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const sendThankYouEmail = async (params: ThankYouEmailParams): Promise<void> => {
  try {
    await api.post('/api/email/send-thank-you', params);
  } catch (error) {
    console.error('Error sending thank you email:', error);
    throw error;
  }
};