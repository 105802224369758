import { z } from 'zod';
// baseSchema.ts
const baseCoachApplicationSchema = z.object({
    // Fields that don't need transformation
    firstName: z.string().min(2, 'First name is required'),
    lastName: z.string().min(2, 'Last name is required'),
    email: z.string().email('Invalid email address'),
    phone: z.string().min(10, 'Valid phone number is required'),
    address: z.object({
        street: z.string().min(1, 'Street address is required'),
        city: z.string().min(1, 'City is required'),
        state: z.string().min(2, 'State is required'),
        zipCode: z.string().min(5, 'Valid ZIP code is required'),
    }),
    tShirtSize: z.enum(['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL']),
    profileImage: z.any(),
    wellnessStory: z.string().min(100, 'Please share more about your story'),
    coachingPersonality: z.string().min(50, 'Please provide more detail about your coaching style'),
    clientFeedback: z.string().min(50, 'Please share more about client feedback'),
    challengingClientScenarios: z.string().min(50, 'Please describe challenging scenarios'),
    recommendCoach: z.string().optional(),
    joinFacebookGroup: z.boolean(),
});
// frontendSchema.ts - for form handling
export const frontendCoachApplicationSchema = baseCoachApplicationSchema.extend({
    // Fields that need transformation for form handling
    profileImage: z
        .any()
        .refine((val) => val && val[0], {
        message: "Profile image is required"
    })
        .transform((val) => val[0]), // Return the first file
    isPhysicalTherapist: z
        .string()
        .nullable()
        .refine((val) => val === 'true' || val === 'false', {
        message: "Please select Yes or No"
    })
        .transform((val) => val === 'true'),
    hobbies: z
        .string()
        .transform((str) => str
        ? str.split(',').map(hobby => hobby.trim()).filter(hobby => hobby.length > 0)
        : [])
        .optional(),
    personalityTraits: z
        .union([z.array(z.string()), z.boolean()])
        .transform((val) => (Array.isArray(val) ? val : []))
        .refine((val) => val.length >= 3, {
        message: "Please select at least 3 personality traits"
    }),
    availability: z.record(z.object({
        morning: z.boolean(),
        afternoon: z.boolean(),
        evening: z.boolean(),
    })),
    certifications: z
        .array(z.object({
        name: z.string().min(1, 'Certification name is required'),
        issuer: z.string().min(1, 'Issuer is required'),
        dateObtained: z.string(),
    }))
        .optional()
        .default([]),
});
// backendSchema.ts - for API validation
export const backendCoachApplicationSchema = baseCoachApplicationSchema.extend({
    // Fields with their expected types after transformation
    profileImage: z.any().refine((val) => val, {
        message: "Profile image is required"
    }),
    isPhysicalTherapist: z.boolean(),
    hobbies: z.array(z.string()).optional(),
    personalityTraits: z.array(z.string()).min(3),
    availability: z.record(z.object({
        morning: z.boolean(),
        afternoon: z.boolean(),
        evening: z.boolean(),
    })),
    certifications: z.array(z.object({
        name: z.string().min(1, 'Certification name is required'),
        issuer: z.string().min(1, 'Issuer is required'),
        dateObtained: z.string(),
    })).optional().default([]),
});
