import { createClient } from '@supabase/supabase-js';

// Create Supabase client
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

interface SubscriptionResult {
  success: boolean;
  duplicate?: boolean;
  message?: string;
}

interface ReferralResult {
  success: boolean;
  duplicate?: boolean;
  message?: string;
}

// Email subscription functions
export const addEmailSubscription = async (
  email: string,
  subscribeNewsletter: boolean,
  source: string
): Promise<SubscriptionResult> => {
  try {
    // Generate a unique ID (cuid)
    const id = Date.now().toString() + Math.random().toString(36).substring(2, 15);
    const now = new Date().toISOString();
    
    const { error } = await supabase
      .from('EmailSubscription')
      .insert([
        { 
          id, 
          email, 
          isCoach: subscribeNewsletter, 
          source, 
          createdAt: now,
          updatedAt: now 
        }
      ]);
      
    if (error) throw error;
    return {
      success: true,
      duplicate: false,
      message: "Subscription added successfully"
    };
  } catch (error) {
    console.error("Error adding subscription:", error);
    return {
      success: false,
      message: "Failed to add subscription"
    };
  }
};

// Referral functions
export const createReferral = async (
  referrerEmail: string,
  referredEmail: string,
  message: string
): Promise<ReferralResult> => {
  try {
    // Generate a unique ID
    const id = Date.now().toString() + Math.random().toString(36).substring(2, 15);
    const now = new Date().toISOString();
    
    const { error } = await supabase
      .from('Referral')
      .insert([
        { 
          id,
          referrerEmail, 
          referredEmail, 
          message,
          status: 'PENDING',
          createdAt: now,
          updatedAt: now
        }
      ]);
      
    if (error) throw error;
    return {
      success: true,
      duplicate: false,
      message: "Referral created successfully"
    };
  } catch (error) {
    console.error("Error creating referral:", error);
    return {
      success: false,
      message: "Failed to create referral"
    };
  }
};

export const updateReferralStatus = async (id: string, status: 'PENDING' | 'ACCEPTED' | 'REJECTED') => {
  try {
    const { data, error } = await supabase
      .from('Referral')
      .update({ status })
      .eq('id', id);
      
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating referral status:', error);
    throw error;
  }
}; 