import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Save } from 'lucide-react';
import Button from '../../components/ui/Button';
import { supabase } from '../../lib/supabase';
import { toast } from 'react-hot-toast';

interface ApplicationData {
  id: string;
  // Basic Info
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  tShirtSize: string;
  profileImage?: string;
  
  // Story
  wellnessStory: string;
  isPhysicalTherapist: boolean;
  hobbies: string[];
  
  // Certifications
  certifications: {
    name: string;
    issuer: string;
    dateObtained: string;
  }[];
  
  // Availability
  availability: {
    [key: string]: {
      morning: boolean;
      afternoon: boolean;
      evening: boolean;
    };
  };
  
  // Personality & Style
  personalityTraits: string[];
  coachingPersonality: string;
  clientFeedback: string;
  challengingClientScenarios: string;
  
  // Additional
  recommendCoach?: string;
  joinFacebookGroup: boolean;
  
  // Application Status
  status: 'pending' | 'approved' | 'rejected';
  reviewNotes: string;
  submittedAt: string;
}

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const TIME_SLOTS = ['morning', 'afternoon', 'evening'] as const;

const ReviewApplication = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [reviewNotes, setReviewNotes] = useState('');
  const [application, setApplication] = useState<ApplicationData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchApplication = async () => {
      if (!id) {
        setError("Application ID is missing");
        setLoading(false);
        return;
      }

      try {
        // Fetch the coach application from Supabase with joined User data for email
        const { data, error } = await supabase
          .from('Coach')
          .select(`
            id, 
            userId,
            applicationStatus,
            adminNotes,
            createdAt,
            firstName,
            lastName,
            phone,
            address,
            tShirtSize,
            profileImage,
            wellnessStory,
            isPhysicalTherapist,
            hobbies,
            certifications,
            availability,
            personalityTraits,
            coachingPersonality,
            clientFeedback,
            challengingClientScenarios,
            recommendCoach,
            joinFacebookGroup,
            User (
              email
            )
          `)
          .eq('id', id)
          .single();

        if (error) {
          throw error;
        }

        if (!data) {
          setError("Application not found");
          setLoading(false);
          return;
        }

        // Get email from User relation
        const email = data.User?.[0]?.email || '';

        // Transform the data into our application format
        const applicationData: ApplicationData = {
          id: data.id,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: email,
          phone: data.phone || '',
          address: data.address || {
            street: '',
            city: '',
            state: '',
            zipCode: ''
          },
          tShirtSize: data.tShirtSize || '',
          profileImage: data.profileImage || undefined,
          wellnessStory: data.wellnessStory || '',
          isPhysicalTherapist: data.isPhysicalTherapist || false,
          hobbies: data.hobbies || [],
          certifications: data.certifications || [],
          availability: data.availability || {
            Monday: { morning: false, afternoon: false, evening: false },
            Tuesday: { morning: false, afternoon: false, evening: false },
            Wednesday: { morning: false, afternoon: false, evening: false },
            Thursday: { morning: false, afternoon: false, evening: false },
            Friday: { morning: false, afternoon: false, evening: false },
            Saturday: { morning: false, afternoon: false, evening: false },
            Sunday: { morning: false, afternoon: false, evening: false }
          },
          personalityTraits: data.personalityTraits || [],
          coachingPersonality: data.coachingPersonality || '',
          clientFeedback: data.clientFeedback || '',
          challengingClientScenarios: data.challengingClientScenarios || '',
          recommendCoach: data.recommendCoach || undefined,
          joinFacebookGroup: data.joinFacebookGroup || false,
          status: data.applicationStatus === 'APPROVED' ? 'approved' : 
                  data.applicationStatus === 'REJECTED' ? 'rejected' : 'pending',
          reviewNotes: data.adminNotes || '',
          submittedAt: data.createdAt || new Date().toISOString()
        };

        setApplication(applicationData);
        setReviewNotes(applicationData.reviewNotes);
        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching application:', error);
        setError(error.message || 'Failed to load application');
        setLoading(false);
      }
    };

    fetchApplication();
  }, [id]);

  const handleSaveNotes = async () => {
    if (!application) return;

    try {
      const { error } = await supabase
        .from('Coach')
        .update({
          adminNotes: reviewNotes
        })
        .eq('id', application.id);

      if (error) throw error;

      setApplication(prev => prev ? {
        ...prev,
        reviewNotes
      } : null);

      toast.success('Notes saved successfully');
    } catch (error: any) {
      console.error('Error saving notes:', error);
      toast.error(error.message || 'Failed to save notes');
    }
  };

  const handleApplicationAction = async (action: 'approve' | 'reject') => {
    if (!application) return;

    try {
      // Map our action to the correct enum value in the database
      const applicationStatus = action === 'approve' ? 'APPROVED' : 'REJECTED';
      
      const { error } = await supabase
        .from('Coach')
        .update({
          applicationStatus: applicationStatus,
          adminNotes: reviewNotes
        })
        .eq('id', application.id);

      if (error) throw error;

      toast.success(`Application ${action === 'approve' ? 'approved' : 'rejected'} successfully`);
      navigate('/admin/applications');
    } catch (error: any) {
      console.error(`Error ${action}ing application:`, error);
      toast.error(error.message || `Failed to ${action} application`);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12 flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading application...</p>
        </div>
      </div>
    );
  }

  if (error || !application) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-4 text-red-600">Error</h1>
          <p className="text-gray-700">{error || "Failed to load application data"}</p>
          <Button 
            className="mt-6"
            onClick={() => navigate('/admin/applications')}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Applications
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <Button 
        variant="secondary" 
        className="mb-8"
        onClick={() => navigate('/admin/applications')}
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Applications
      </Button>

      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 border-b">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold mb-2">Review Coach Application</h1>
              <p className="text-gray-600">
                Submitted on {new Date(application.submittedAt).toLocaleDateString()}
              </p>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm ${
              application.status === 'approved' ? 'bg-green-100 text-green-800' :
              application.status === 'rejected' ? 'bg-red-100 text-red-800' :
              'bg-yellow-100 text-yellow-800'
            }`}>
              {application.status.charAt(0).toUpperCase() + application.status.slice(1)}
            </span>
          </div>
        </div>

        <div className="p-6 space-y-8">
          {/* Basic Information */}
          <section>
            <h2 className="text-lg font-bold mb-4">Basic Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Full Name</label>
                <p className="mt-1">{application.firstName} {application.lastName}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <p className="mt-1">{application.email}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <p className="mt-1">{application.phone}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">T-Shirt Size</label>
                <p className="mt-1">{application.tShirtSize}</p>
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <p className="mt-1">
                  {application.address.street}, {application.address.city}, {application.address.state} {application.address.zipCode}
                </p>
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">Profile Image</label>
                {application.profileImage ? (
                  <div className="relative w-32 h-32">
                    <img 
                      src={application.profileImage}
                      alt="Profile"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ) : (
                  <div className="w-32 h-32 bg-gray-100 rounded-lg flex items-center justify-center">
                    <User className="w-12 h-12 text-gray-400" />
                    <p className="text-sm text-gray-500 mt-2">No profile image</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/* Background & Experience */}
          <section>
            <h2 className="text-lg font-bold mb-4">Background & Experience</h2>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Wellness Story</label>
                <p className="mt-1 text-gray-600">{application.wellnessStory}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Physical Therapist</label>
                <p className="mt-1">{application.isPhysicalTherapist ? 'Yes' : 'No'}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Hobbies & Interests</label>
                <div className="mt-1 flex flex-wrap gap-2">
                  {application.hobbies.map(hobby => (
                    <span key={hobby} className="px-3 py-1 bg-gray-100 rounded-full text-sm">
                      {hobby}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {/* Certifications */}
          <section>
            <h2 className="text-lg font-bold mb-4">Certifications</h2>
            <div className="space-y-4">
              {application.certifications.map((cert, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Name</label>
                      <p className="mt-1">{cert.name}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Issuer</label>
                      <p className="mt-1">{cert.issuer}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Date Obtained</label>
                      <p className="mt-1">{new Date(cert.dateObtained).toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Availability */}
          <section>
            <h2 className="text-lg font-bold mb-4">Availability</h2>
            <div className="bg-gray-50 rounded-lg p-4">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left py-2">Day</th>
                    {TIME_SLOTS.map(slot => (
                      <th key={slot} className="text-left py-2 capitalize">
                        {slot}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {WEEKDAYS.map(day => (
                    <tr key={day} className="border-t">
                      <td className="py-2">{day}</td>
                      {TIME_SLOTS.map(slot => (
                        <td key={`${day}-${slot}`} className="py-2">
                          {application.availability[day][slot] ? (
                            <span className="text-green-600">✓</span>
                          ) : (
                            <span className="text-red-600">✗</span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/* Coaching Style */}
          <section>
            <h2 className="text-lg font-bold mb-4">Coaching Style</h2>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Personality Traits</label>
                <div className="mt-1 flex flex-wrap gap-2">
                  {application.personalityTraits.map(trait => (
                    <span key={trait} className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-sm">
                      {trait}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Coaching Personality</label>
                <p className="mt-1 text-gray-600">{application.coachingPersonality}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Client Feedback Experience</label>
                <p className="mt-1 text-gray-600">{application.clientFeedback}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Handling Challenging Scenarios</label>
                <p className="mt-1 text-gray-600">{application.challengingClientScenarios}</p>
              </div>
            </div>
          </section>

          {/* Additional Information */}
          <section>
            <h2 className="text-lg font-bold mb-4">Additional Information</h2>
            <div className="space-y-4">
              {application.recommendCoach && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Coach Recommendation</label>
                  <p className="mt-1">{application.recommendCoach}</p>
                </div>
              )}
              <div>
                <label className="block text-sm font-medium text-gray-700">Facebook Group Interest</label>
                <div className="mt-1 flex items-center">
                  <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
                    application.joinFacebookGroup 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {application.joinFacebookGroup 
                      ? 'Interested in joining Wellness Weirdos Coaches Facebook Group' 
                      : 'Not interested in Facebook Group'}
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* Review Notes */}
          <section>
            <h2 className="text-lg font-bold mb-4">Review Notes</h2>
            <textarea
              value={reviewNotes}
              onChange={(e) => setReviewNotes(e.target.value)}
              rows={4}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              placeholder="Add your review notes..."
            />
            <div className="mt-4">
              <Button onClick={handleSaveNotes}>
                <Save className="w-4 h-4 mr-2" />
                Save Notes
              </Button>
            </div>
          </section>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4 pt-6 border-t">
            <Button
              variant="secondary"
              onClick={() => navigate('/admin/applications')}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleApplicationAction('reject')}
              className="bg-red-50 text-red-600 hover:bg-red-100"
            >
              Reject Application
            </Button>
            <Button
              onClick={() => handleApplicationAction('approve')}
              className="bg-green-600 hover:bg-green-700"
            >
              Approve Application
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewApplication;