import { SignIn } from '@clerk/clerk-react';
// import { toast } from 'react-hot-toast';


const AdminLogin = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Admin Login</h2>
        
        <SignIn 
          
          routing="hash"
          signUpUrl="/admin/register"
          redirectUrl="/auth/callback"
          appearance={{
            elements: {
              formButtonPrimary: 'bg-[#FF4D8D] hover:bg-[#E6005C]',
              formFieldInput: 'focus:border-[#FF4D8D] focus:ring-[#FF4D8D]',
              headerTitle: 'text-[#FF4D8D]',
              headerSubtitle: 'text-gray-600',
              footerAction: 'text-[#FF4D8D]',
              formFieldLabel: 'text-gray-700'
            }
          }}
        />
        
        <div className="mt-4 text-center text-sm text-gray-600">
          <p>Admin access only. Regular users should use the main login.</p>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin; 