import { ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-b from-[#FFF7E6] to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left Column - Text Content */}
          <div>
            <div className="mb-4">
              <h2 className="block text-base text-[#FF4D8D] font-semibold tracking-wide uppercase">
                Your Personalized Path to
              </h2>
            </div>
            <div className="mb-4">
              <h1 className=" text-5xl font-bold">
                Lasting Wellness
              </h1>
            </div>
            <div className="mb-8">
              <h1 className=" text-5xl text-[#FF4D8D] font-bold">
                Begins Here!
              </h1>
            </div>
            
            <div className="mb-12">
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                From physical therapy to sustainable movement, we help you bridge the gap to lifelong wellness
              </p>
            </div>

            <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row mb-6">
            <Button to="/find-coach" className="mr-4">
                Find Your Coach
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
              <Button to="/auth/callback" variant="secondary">
                Become a Coach
              </Button>
            </div>

            <p className="text-sm text-gray-600">
              Join 1000+ weirdos who've found their path to wellness
            </p>
          </div>

          {/* Right Column - Image */}
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dork9pzwh/image/upload/v1739368220/1_Jordan_nexhks.jpg"
                alt="Virtual coaching session"
              className="rounded-2xl shadow-lg w-full"
            />
          </div>
        </div>




        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12"> 
          <div></div>
        <div><p className="text-md leading-relaxed italic mt-3 text-gray-500">
            Meet Jordan. After finishing physical therapy, they felt stuck—progress plateaued, old 
            pains crept back, and movement felt daunting. They dreamed of getting back to an 
            active life but didn't know where to start. That's where we come in.
          </p></div>
        </div>
        
        
        {/* <div className="absolute bottom-8 right-0 max-w-xl text-right px-4">
          <p className="text-[#FF4D4D] text-lg leading-relaxed">
            Meet Jordan. After finishing physical therapy, they felt stuck—progress plateaued, old 
            pains crept back, and movement felt daunting. They dreamed of getting back to an 
            active life but didn't know where to start. That's where we come in.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;