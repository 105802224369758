const WhyWellnessGap = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center">
      <h3 className="text-base mb-3 text-[#FF69B4] font-semibold tracking-wide uppercase">
            Empowerment
          </h3>
        <h2 className="text-4xl font-bold text-gray-900 mb-8">
          Turn the Wellness Gap into Your Greatest Strength
        </h2>

        <div className="mb-8">
          
          <p className="text-xl text-gray-500 mb-12">
            From avoiding pain to embracing possibilities.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <img 
            src="https://res.cloudinary.com/dork9pzwh/image/upload/v1739447050/2_Jordan_zrzhzf.jpg"
            alt="Person in gray sweater"
            className="rounded-xl shadow-lg w-full"
          />
          <img 
            src="https://res.cloudinary.com/dork9pzwh/image/upload/v1739453814/5_Jordan.pngedit_rcy2cv.png"
            alt="Person having virtual coaching session"
            className="rounded-xl shadow-lg w-full"
          />
        </div>

        <p className="text-xl text-gray-500 mb-12">
          Renewed strength, confidence in movement, and a sense of control over wellness.
        </p>

        <p className="text-xl italic text-center text-gray-500 max-w-4xl mx-auto">
          "With expert coaching and personalized sessions, Jordan learned to move with confidence. Now, they're back to hiking with friends and enjoying life to the fullest."
        </p>
      </div>
    </div>
  </section>
);
};

export default WhyWellnessGap; 