import { Heart, Shield, Clock } from 'lucide-react';

const PerfectMatch = () => {
  const features = [
    {
      icon: Heart,
      title: "Personalized Matching",
      description: "Our intelligent matching system pairs you with coaches who understand your specific needs and goals."
    },
    {
      icon: Shield,
      title: "Certified Experts",
      description: "All our coaches are certified professionals with extensive experience in movement and recovery."
    },
    {
      icon: Clock,
      title: "Flexible Options",
      description: "Choose from single sessions and other packages to fit your needs and schedule."
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <p className="text-[#FF69B4] font-semibold uppercase tracking-wide">
            FIND YOUR COACH
          </p>
          <h2 className="mt-2 text-4xl font-bold text-gray-900">
            Your Perfect Movement Match Awaits
          </h2>
          <p className="mt-4 text-xl text-gray-600 max-w-2xl mx-auto">
            Take the first step towards sustainable wellness with a coach who gets your unique journey
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="relative">
            <img 
              src="https://res.cloudinary.com/dork9pzwh/image/upload/v1739452999/16_Jordan_xemvp5.jpg"
              alt="Virtual coaching session"
              className="rounded-xl shadow-lg w-full"
            />
          </div>
          
          <div>
            <h3 className="text-2xl font-bold text-gray-900 mb-8">
              What to Expect
            </h3>
            <div className="space-y-8">
              {features.map((feature, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0">
                    <feature.icon className="h-6 w-6 text-[#FF69B4]" />
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-gray-900">
                      {feature.title}
                    </h4>
                    <p className="mt-2 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfectMatch; 