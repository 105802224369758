import { useFormContext } from 'react-hook-form';

const Story = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Your Wellness Weirdo Story
        </label>
        <textarea
          {...register('wellnessStory')}
          rows={6}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.wellnessStory ? 'border-red-300' : ''
          }`}
          placeholder="Share your journey and what makes you uniquely qualified to be a Wellness Weirdo coach..."
        />
        {errors.wellnessStory && (
          <p className="mt-1 text-sm text-red-600">{errors.wellnessStory.message as string}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Are you a Physical Therapist?
        </label>
        <div className="flex items-center space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              {...register('isPhysicalTherapist')}
              value="true"
              className="text-[#FF4D8D] focus:ring-[#FF4D8D]"
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              {...register('isPhysicalTherapist')}
              value="false"
              className="text-[#FF4D8D] focus:ring-[#FF4D8D]"
            />
            <span className="ml-2">No</span>
          </label>
        </div>
        {errors.isPhysicalTherapist && (
          <p className="mt-1 text-sm text-red-600">{errors.isPhysicalTherapist.message as string}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Hobbies & Interests
        </label>
        <textarea
          {...register('hobbies')}
          rows={3}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.hobbies ? 'border-red-300' : ''
          }`}
          placeholder="Share your hobbies and interests (comma-separated)"
        />
        {errors.hobbies && (
          <p className="mt-1 text-sm text-red-600">{errors.hobbies.message as string}</p>
        )}
      </div>
    </div>
  );
};

export default Story;