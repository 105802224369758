import { useState } from 'react';
import { ArrowLeft, Mail } from 'lucide-react';
import Button from '../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { sendThankYouEmail } from '../services/email';
import {  addEmailSubscription } from '../services/database';
import { toast } from 'react-hot-toast';

const ComingSoon = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Store email subscription in database
      const result = await addEmailSubscription(email, false, 'coming_soon');
      
      // Check if it's a duplicate
      if (result.duplicate) {
        toast.success(result.message || 'You are already subscribed with this email address.');
        setEmail('');
        setSubscribeNewsletter(false);
        setIsSubmitting(false);
        return;
      }
      
      // Send thank you email
      await sendThankYouEmail({
        to_email: email,
        to_name: email.split('@')[0], // Simple name extraction from email
        message: `Thank you for your interest in Wellness Weirdos! ${
          subscribeNewsletter 
            ? "We'll keep you updated with our newsletter about our launch and wellness tips." 
            : "We'll notify you when we launch."
        }`
      });

      toast.success('Thank you for signing up! Please check your email.');
      setEmail('');
      setSubscribeNewsletter(false);
    } catch (error) {
      console.error('Error processing submission:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background-light to-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <Button 
          variant="secondary" 
          className="mb-8"
          onClick={() => navigate('/')}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </Button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Matchmaking Launches in April!
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Be the first to know when we launch our innovative coach matching platform.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                  Email Address
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="pl-10 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                    placeholder="you@example.com"
                    required
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={subscribeNewsletter}
                  onChange={(e) => setSubscribeNewsletter(e.target.checked)}
                  className="rounded text-primary focus:ring-primary"
                  disabled={isSubmitting}
                />
                <span className="text-gray-700">Subscribe to our newsletter for updates and wellness tips</span>
              </label>

              <Button 
                type="submit" 
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Notify Me'}
              </Button>
            </form>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative h-72 rounded-2xl overflow-hidden shadow-xl">
              <video
                className="object-cover w-full h-full"
                autoPlay
                muted
                loop
                playsInline
              >
                <source 
                  src="https://res.cloudinary.com/dork9pzwh/video/upload/v1740743137/6696216-uhd_2160_3840_24fps_eioaxg.mp4" 
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            
            <div className="relative h-72 rounded-2xl overflow-hidden shadow-xl">
              <video
                className="object-cover w-full h-full"
                autoPlay
                muted
                loop
                playsInline
              >
                <source 
                  src="https://res.cloudinary.com/dork9pzwh/video/upload/v1740743163/6696218-uhd_2160_3840_24fps_la4vgo.mp4" 
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ComingSoon;