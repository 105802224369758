import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import {
  LayoutDashboard,
  Calendar,
  MessageSquare,
  Users,
  Settings,
  LogOut,
  Clock,
  Video,
  CreditCard,
  FileText,
  Mail,
  // HelpCircle,
} from 'lucide-react';
import Logo from '../components/ui/Logo';

interface SidebarLink {
  icon: React.ElementType;
  label: string;
  path: string;
}

const coachLinks: SidebarLink[] = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/coach' },
  { icon: Calendar, label: 'Schedule', path: '/coach/schedule' },
  { icon: Calendar, label: 'Calendar', path: '/coach/calendar' },
  { icon: Users, label: 'Clients', path: '/coach/clients' },
  { icon: MessageSquare, label: 'Messages', path: '/coach/messages' },
  { icon: Clock, label: 'Availability', path: '/coach/availability' },
  { icon: Settings, label: 'Settings', path: '/coach/settings' },
];

const clientLinks: SidebarLink[] = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/client' },
  { icon: Calendar, label: 'Sessions', path: '/client/sessions' },
  { icon: MessageSquare, label: 'Messages', path: '/client/messages' },
  { icon: Users, label: 'Find Coach', path: '/client/find-coach' },
  { icon: Video, label: 'Video Library', path: '/client/videos' },
  { icon: CreditCard, label: 'Billing', path: '/client/billing' },
  { icon: Settings, label: 'Settings', path: '/client/settings' },
];

const adminLinks: SidebarLink[] = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/admin' },
  // { icon: Users, label: 'Coaches', path: '#1' },
  // { icon: Users, label: 'Clients', path: '#2' },
  { icon: FileText, label: 'Applications', path: '/admin/applications' },
  { icon: Mail, label: 'Mailing List', path: '/admin/email-management' },
  // { icon: Users, label: 'Matching', path: '#3' },
  // { icon: MessageSquare, label: 'Messages', path: '#4' },
  // { icon: HelpCircle, label: 'Help Desk', path: '#5' },
  // { icon: Settings, label: 'Settings', path: '#6' },
];


const DashboardLayout = () => {
  const location = useLocation();
  const role = location.pathname.split('/')[1]; // 'coach', 'client', or 'admin'
  
  const links = {
    coach: coachLinks,
    client: clientLinks,
    admin: adminLinks,
  }[role] || [];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
        <div className="flex flex-col h-full">
          {/* Logo */}
          <div className="p-6 border-b">
            <Logo />
          </div>

          {/* Navigation */}
          <nav className="flex-1 p-4 space-y-1">
            {links.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`flex items-center px-4 py-3 rounded-lg transition-colors ${
                  location.pathname === link.path
                    ? 'bg-primary text-white'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <link.icon className="w-5 h-5 mr-3" />
                {link.label}
              </Link>
            ))}
          </nav>

          {/* Footer */}
          <div className="p-4 border-t">
            <button className="flex items-center w-full px-4 py-3 text-gray-600 rounded-lg hover:bg-gray-50">
              <LogOut className="w-5 h-5 mr-3" />
              Sign Out
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="ml-64 p-8">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;