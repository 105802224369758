import { useEffect, useState } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import CoachApplicationForm from '../../components/forms/CoachApplicationForm';
import type { FrontendCoachApplication } from "@wellness-weirdos/shared";
import LoadingSpinner from '../../components/ui/LoadingSpinner';

const EditApplication = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [applicationData, setApplicationData] = useState<FrontendCoachApplication | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        if (!user?.id) return;
        
        const token = await getToken();
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/api/coach/application/${user.id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch application');
        }

        const data = await response.json();
        setApplicationData(data);
      } catch (error) {
        console.error('Error fetching application:', error);
        setError(error instanceof Error ? error.message : 'Failed to load application');
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [user, getToken]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="text-blue-600 hover:underline"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-background-light to-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Edit Your Application
        </h1>
        <CoachApplicationForm 
          mode="edit" 
          existingData={applicationData || undefined}
        />
      </div>
    </div>
  );
};

export default EditApplication;