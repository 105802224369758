const Benefits = () => {
  const images = [
    "https://res.cloudinary.com/dork9pzwh/image/upload/v1739452711/10_Jordan_htxcgd.jpg",
    "https://res.cloudinary.com/dork9pzwh/image/upload/v1739452710/11_Jordan_ww0kj5.jpg",
    "https://res.cloudinary.com/dork9pzwh/image/upload/v1739452711/12_Jordan_hx2eod.jpg"
  ];

  const benefitPoints = [
    "Transitioning from PT but not ready for full independence.",
    "Experiencing post-PT flare-ups or feeling \"off\" and knowing movement is the key.",
    "Seeking a structured approach to sustainable wellness."
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">
            Who Benefits from Wellness Weirdos?
          </h2>
          <div className="space-y-4 mb-12">
            {benefitPoints.map((point, index) => (
              <p key={index} className="text-xl text-gray-500">
                {point}
              </p>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {images.map((image, index) => (
            <div key={index} className="rounded-xl overflow-hidden shadow-lg">
              <img 
                src={image}
                alt={`Wellness journey ${index + 1}`}
                className="w-full h-80 object-cover"
              />
            </div>
          ))}
        </div>

        <div className="text-center">
          <p className="text-xl text-gray-500 italic">
            For Jordan, Wellness Weirdos was the missing puzzle piece to reclaim their active lifestyle.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Benefits; 