import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { frontendCoachApplicationSchema, type FrontendCoachApplication } from "@wellness-weirdos/shared";
import StepIndicator from './StepIndicator';
import {
  BasicInfo,
  Story,
  Certifications,
  Availability,
  PersonalityTraits,
  CoachingStyle,
} from './steps';
import Button from '../../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';
import { toast } from 'react-hot-toast';

const STEPS = ['Basic Info', 'Your Story', 'Certifications', 'Availability', 'Personality', 'Coaching Style'];

interface CoachApplicationFormProps {
  mode?: 'create' | 'edit';
  existingData?: FrontendCoachApplication;
}

const CoachApplicationForm = ({ mode = 'create', existingData }: CoachApplicationFormProps) => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { user } = useUser();
  const [currentStep, setCurrentStep] = useState(0);

  const methods = useForm<FrontendCoachApplication>({
    resolver: zodResolver(frontendCoachApplicationSchema),
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: ''
      },
      tShirtSize: 'M',
      profileImage: [],
      wellnessStory: '',
      isPhysicalTherapist: false,
      hobbies: [],
      certifications: [],
      availability: {},
      personalityTraits: [],
      coachingPersonality: '',
      clientFeedback: '',
      challengingClientScenarios: '',
      recommendCoach: '',
      joinFacebookGroup: false,
    }
  });

  // Load existing data if in edit mode
  useEffect(() => {
    if (mode === 'edit' && existingData) {
      methods.reset(existingData);
    } else if (user) {
      // Pre-fill form with user data from Clerk
      methods.reset({
        ...methods.getValues(),
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.emailAddresses[0]?.emailAddress || '',
      });
    }
  }, [user, existingData, mode, methods]);

  const validateCurrentStep = async () => {
    const fields = getFieldsForStep(currentStep);
    const result = await methods.trigger(fields);
    return result;
  };

  const handleNext = async () => {
    const isValid = await validateCurrentStep();
    console.log('Validation result:', isValid);
    
    if (isValid) {
      if (currentStep < STEPS.length - 1) {
        setCurrentStep(prev => prev + 1);
      } else {
        // On last step, submit the form
        const formData = methods.getValues();
        await onSubmit(formData);
      }
    } else {
      // Log validation errors
      console.log('Form errors:', methods.formState.errors);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const onSubmit = async (data: FrontendCoachApplication) => {
    try {
      const token = await getToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const formData = new FormData();
      
      // Handle profile image
      if (data.profileImage && data.profileImage[0]) {
        formData.append('profileImage', data.profileImage[0]);
      }

      formData.append('data', JSON.stringify(data));

      const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000';
      
      const endpoint = mode === 'edit' 
        ? `${API_URL}/api/coach/application/${user?.id}` 
        : `${API_URL}/api/coach/application`;

      const method = mode === 'edit' ? 'PUT' : 'POST';
      
      console.log(`${mode === 'edit' ? 'Updating' : 'Submitting'} form data:`, data);

      const response = await fetch(endpoint, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `Failed to ${mode === 'edit' ? 'update' : 'submit'} application`);
      }

      const responseData = await response.json();
      console.log('Response:', responseData);

      toast.success(mode === 'edit' ? 'Application updated successfully!' : 'Application submitted successfully!');
      navigate('/coach/dashboard');
    } catch (error) {
      console.error(`Error ${mode === 'edit' ? 'updating' : 'submitting'} application:`, error);
      toast.error(error instanceof Error ? error.message : `Failed to ${mode === 'edit' ? 'update' : 'submit'} application`);
    }
  };

  const getFieldsForStep = (step: number): (keyof FrontendCoachApplication)[] => {
    switch (step) {
      case 0: // Basic Info
        return ['firstName', 'lastName', 'email', 'phone', 'address', 'tShirtSize', 'profileImage'];
      case 1: // Story
        return ['wellnessStory', 'isPhysicalTherapist', 'hobbies'];
      case 2: // Certifications
        return ['certifications'];
      case 3: // Availability
        return ['availability'];
      case 4: // Personality
        return ['personalityTraits'];
      case 5: // Coaching Style
        return ['coachingPersonality', 'clientFeedback', 'challengingClientScenarios', 'recommendCoach', 'joinFacebookGroup'];
      default:
        return [];
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={(e) => e.preventDefault()} className="space-y-8">
        <StepIndicator steps={STEPS} currentStep={currentStep} />
        
        <div className="mt-8">
          {currentStep === 0 && <BasicInfo />}
          {currentStep === 1 && <Story />}
          {currentStep === 2 && <Certifications />}
          {currentStep === 3 && <Availability />}
          {currentStep === 4 && <PersonalityTraits />}
          {currentStep === 5 && <CoachingStyle />}
        </div>

        <div className="flex justify-between mt-8">
          <Button
            type="button"
            variant="secondary"
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            Back
          </Button>
          <Button
            type="button"
            onClick={handleNext}
          >
            {currentStep === STEPS.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CoachApplicationForm;