import { useFieldArray, useForm } from 'react-hook-form';
import Button from '../../../ui/Button';
import { Plus, Trash2 } from 'lucide-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { frontendCoachApplicationSchema, type FrontendCoachApplication } from "@wellness-weirdos/shared";

const Certifications = () => {
  const { register, control, formState: { errors } } = useForm<FrontendCoachApplication>({
    resolver: zodResolver(frontendCoachApplicationSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certifications',
  });

  return (
    <div className="space-y-6">
      {fields.map((field, index) => (
        <div key={field.id} className="p-4 bg-gray-50 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-medium">Certification {index + 1}</h4>
            <button
              type="button"
              onClick={() => remove(index)}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Certification Name
              </label>
              <input
                {...register(`certifications.${index}.name`)}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
                  errors.certifications?.[index]?.name ? 'border-red-300' : ''
                }`}
              />
              {errors.certifications?.[index]?.name && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.certifications[index].name?.message as string}
                </p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Issuing Organization
              </label>
              <input
                {...register(`certifications.${index}.issuer`)}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
                  errors.certifications?.[index]?.issuer ? 'border-red-300' : ''
                }`}
              />
              {errors.certifications?.[index]?.issuer && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.certifications[index].issuer?.message as string}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date Obtained
            </label>
            <input
              type="date"
              {...register(`certifications.${index}.dateObtained`)}
              className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
                errors.certifications?.[index]?.dateObtained ? 'border-red-300' : ''
              }`}
            />
            {errors.certifications?.[index]?.dateObtained && (
              <p className="mt-1 text-sm text-red-600">
                {errors.certifications[index].dateObtained?.message as string}
              </p>
            )}
          </div>
        </div>
      ))}

      {errors.certifications && !Array.isArray(errors.certifications) && (
        <p className="text-sm text-red-600 mb-4">{errors.certifications.message as string}</p>
      )}

      <Button
        type="button"
        variant="secondary"
        onClick={() => append({ name: '', issuer: '', dateObtained: '' })}
        className="w-full"
      >
        <Plus className="w-5 h-5 mr-2" />
        Add Certification
      </Button>
    </div>
  );
};

export default Certifications;