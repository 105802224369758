import { useFormContext } from 'react-hook-form';
import FormField from '../../../ui/FormField';
import { TSHIRT_SIZES } from '../../../../utils/constants';
import type { FrontendCoachApplication } from "@wellness-weirdos/shared";

const BasicInfo = () => {
  const { register, formState: { errors } } = useFormContext<FrontendCoachApplication>();

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField
          label="First Name"
          {...register('firstName')}
          placeholder="Your first name"
          error={errors.firstName?.message}
          required
        />
        <FormField
          label="Last Name"
          {...register('lastName')}
          placeholder="Your last name"
          error={errors.lastName?.message}
          required
        />
      </div>

      <FormField
        label="Email"
        type="email"
        {...register('email')}
        placeholder="your.email@example.com"
        error={errors.email?.message}
        required
      />

      <FormField
        label="Phone"
        type="tel"
        {...register('phone')}
        placeholder="(555) 555-5555"
        error={errors.phone?.message}
        required
      />

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Mailing Address</h3>
        <FormField
          label="Street Address"
          {...register('address.street')}
          placeholder="123 Main St"
          error={errors.address?.street?.message}
          required
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <FormField
            label="City"
            {...register('address.city')}
            placeholder="City"
            error={errors.address?.city?.message}
            required
          />
          <FormField
            label="State"
            {...register('address.state')}
            placeholder="State"
            error={errors.address?.state?.message}
            required
          />
          <FormField
            label="ZIP Code"
            {...register('address.zipCode')}
            placeholder="12345"
            error={errors.address?.zipCode?.message}
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          T-Shirt Size (Unisex) <span className="text-red-500">*</span>
        </label>
        <select
          {...register('tShirtSize')}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.tShirtSize ? 'border-red-300' : ''
          }`}
        >
          <option value="">Select a size</option>
          {TSHIRT_SIZES.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        {errors.tShirtSize && (
          <p className="mt-1 text-sm text-red-600">{errors.tShirtSize.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Profile Image  <span className="text-red-500">* </span> <br />
          <span className='text-xs italic'> less than 1MB</span> 
        </label>
        <input
          type="file"
          accept="image/*"
          {...register('profileImage')}
          className={`block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-[#FF4D8D] file:text-white hover:file:bg-[#E6005C] ${
            errors.profileImage ? 'border-red-300' : ''
          }`}
        />
        {errors.profileImage && (
          <p className="mt-1 text-sm text-red-600">{errors.profileImage.message?.toString()}</p>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;