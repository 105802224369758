// src/auth/ProtectedRoute.tsx
import { useUser, RedirectToSignIn } from '@clerk/clerk-react';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoaded, isSignedIn } = useUser();
  
  // Wait for auth to load
  if (!isLoaded) {
    return null;
  }

  if (!isSignedIn) {
    // Use Clerk's built-in redirect component
    sessionStorage.setItem('redirectAfterAuth', '/coach/dashboard');
    return <RedirectToSignIn />;
  }

  // User is authenticated, render the protected content
  return <>{children}</>;
};