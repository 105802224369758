import { useNavigate } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'accent';
  size?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
  to?: string;   // Add a `to` prop for internal routes
  href?: string; // Add an `href` prop for external links, href overrides to
}

const Button = ({ 
  variant = 'primary', 
  size = 'md', 
  children, 
  className = '',
  to, 
  href,
  ...props 
}: ButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to); // Navigate to the route
    }
  };

  const baseStyles = 'inline-flex items-center justify-center rounded-lg font-medium transition-all duration-200 transform hover:scale-105';
  
  const variants = {
    primary: 'bg-primary hover:bg-primary-dark text-white shadow-lg hover:shadow-xl',
    secondary: 'bg-secondary hover:bg-secondary-dark text-neutral-800 shadow-lg hover:shadow-xl',
    accent: 'bg-accent hover:bg-accent-dark text-white shadow-lg hover:shadow-xl',
  };

  const sizes = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3 text-base',
    lg: 'px-8 py-4 text-lg',
  };

  if (href) {
    return (
      <a
        className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
        href={href}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)} // Explicitly cast props for anchor
      >
        {children}
      </a>
    );
  }

  return (
    <button 
      className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
      onClick={to ? handleClick : props.onClick} // Handle navigation if `to` is provided
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;