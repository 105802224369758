import { Users, Video, Calendar, Activity } from 'lucide-react';

const features = [
  {
    name: 'Expert Matching',
    description: 'Get paired with coaches who understand your specific needs and recovery journey.',
    icon: Users,
  },
  {
    name: 'Tailored Coaching',
    description: 'Personalized plans designed to address your unique needs and goals.',
    icon: Video,
  },
  {
    name: 'Holistic Movement',
    description: 'Integrating physical therapy principles into everyday life.',
    icon: Calendar,
  },
  {
    name: 'Ongoing Support',
    description: 'Continuous guidance to help you stay on track and avoid setbacks.',
    icon: Activity,
  },
];

const Features = () => {
  return (
    <div className="bg-white py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-[#FF4D8D] font-semibold tracking-wide uppercase">
            Platform Features
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Everything you need for continued recovery
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
            Our platform provides all the tools necessary for a successful post-PT journey.
          </p>
        </div>

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gradient-to-b from-[#FFF7E6] to-white rounded-xl px-6 pb-8 h-full transform hover:scale-105 transition-transform duration-300">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-[#FF4D8D] rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
                
              </div>
            ))}
          </div>
        
        </div>
        
      </div>
      <div className="text-center">
          <p className="text-lg mt-10 italic text-gray-500 max-w-4xl mx-auto">
          Our features gave Jordan the tools to stay consistent and motivated, even after their sessions ended.
          </p>
        </div>
    </div>
  );
};

export default Features;