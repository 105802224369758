// API utility for making requests to your backend
export const api = {
    get: async (endpoint: string) => {
      const response = await fetch(`${import.meta.env.VITE_API_URL}${endpoint}`);
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    
    post: async (endpoint: string, data: any) => {
      const response = await fetch(`${import.meta.env.VITE_API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    
    // Add other methods as needed (PUT, DELETE, etc.)
  };