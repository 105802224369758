import { useState } from 'react';
import { ArrowLeft, Plus, Trash2, Mail } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Button from '../../components/ui/Button';
import { sendReferralEmail } from '../../services/email';
import { toast } from 'react-hot-toast';
import { createReferral } from '../../services/database';

const ReferralForm = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { type } = useParams<{ type: 'coach' | 'client' }>();
  const [emails, setEmails] = useState<string[]>(['']);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const handleEmailChange = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addEmailField = () => {
    setEmails([...emails, '']);
  };

  const removeEmailField = (index: number) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSending(true);
    console.log('Starting form submission');

    try {
      const validEmails = emails.filter(email => email.trim() !== '');
      console.log('Valid emails:', validEmails);
      const referrerEmail = user?.primaryEmailAddress?.emailAddress || '';
      console.log('Referrer email:', referrerEmail);
      
      // Before Promise.all
      console.log('About to process emails');
      
      // Keep track of results
      const results = await Promise.all(
        validEmails.map(async (email) => {
          console.log('Processing email:', email);
          // Store in database
          const referralResult = await createReferral(referrerEmail, email, message);
          console.log('Referral result for', email, ':', referralResult);
          
          // Check if result exists and is not a duplicate
          if (referralResult && !referralResult.duplicate) {
            console.log('Sending email to', email);
            try {
              await sendReferralEmail({
                to_email: email,
                to_name: email.split('@')[0], // Simple name extraction
                message: message,
                from_name: user?.firstName ?? 'Your Coach'
              });
              console.log('Email sent successfully to', email);
            } catch (emailError) {
              console.error('Error sending email to', email, ':', emailError);
            }
          }
          
          return {
            email,
            success: referralResult?.success ?? false,
            duplicate: referralResult?.duplicate ?? false,
            message: referralResult?.message ?? 'Unknown status'
          };
        })
      );
      
      console.log('All emails processed, results:', results);
      
      // Check if we have any duplicates
      const duplicates = results.filter(r => r.duplicate);
      console.log('Duplicates:', duplicates);
      
      console.log('About to show toast');
      toast.success('Invitation sent successfully!');
      
      console.log('About to navigate');
      navigate('/coach/dashboard');
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error('Failed to send invitation. Please try again.');
    } finally {
      console.log('Finally block executed');
      setSending(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background-light to-white">
      <div className="max-w-2xl mx-auto px-4 py-12">
        <Button 
          variant="secondary" 
          className="mb-8"
          onClick={() => navigate('/coach/dashboard')}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Dashboard
        </Button>
        
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Refer a {type === 'coach' ? 'Coach' : 'Client'}
          </h1>
          <p className="text-xl text-gray-600">
            {type === 'coach' 
              ? 'Know someone who would be a great Wellness Weirdos coach?' 
              : 'Help someone start their wellness journey with us'}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            {emails.map((email, index) => (
              <div key={index} className="flex gap-2">
                <div className="relative flex-1">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    className="pl-10 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                    placeholder="email@example.com"
                    required
                  />
                </div>
                {emails.length > 1 && (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => removeEmailField(index)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                )}
              </div>
            ))}
          </div>

          <Button
            type="button"
            variant="secondary"
            onClick={addEmailField}
            className="w-full"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Another Email
          </Button>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Personal Message (Optional)
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={4}
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              placeholder="Add a personal note to your invitation..."
            />
          </div>

          <Button 
            type="submit" 
            className="w-full"
            disabled={sending}
          >
            {sending ? 'Sending...' : 'Send Invitation'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ReferralForm;