import { useFormContext } from 'react-hook-form';
import { PERSONALITY_TRAITS } from '../../../../utils/constants';

const PersonalityTraits = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-4">
          Select traits that best describe you as a coach (select at least 3):
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {PERSONALITY_TRAITS.map((trait) => (
            <label key={trait} className="inline-flex items-center">
              <input
                type="checkbox"
                value={trait}
                {...register('personalityTraits', { 
                  setValueAs: (value) => value // Ensure value is passed through
                })}
                className={`rounded text-[#FF4D8D] focus:ring-[#FF4D8D] ${
                  errors.personalityTraits ? 'border-red-300' : ''
                }`}
              />
              <span className="ml-2">{trait}</span>
            </label>
          ))}
        </div>
        {errors.personalityTraits && (
          <p className="mt-2 text-sm text-red-600">{errors.personalityTraits.message as string}</p>
        )}
      </div>
    </div>
  );
};

export default PersonalityTraits;