import CoachApplicationForm from '../components/forms/CoachApplicationForm';
import { ArrowLeft, Sparkles } from 'lucide-react';
import Button from '../components/ui/Button';

const BecomeCoach = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FFF7E6] to-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Button 
          variant="secondary" 
          className="mb-12"
          onClick={() => window.history.back()}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back
        </Button>
        
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center w-16 h-16 bg-[#FF4D8D] rounded-2xl shadow-lg mb-6">
            <Sparkles className="w-8 h-8 text-white" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Join Our Coaching Community
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Share your unique approach to wellness and help others embrace their weird journey to better movement
          </p>
        </div>

        <div className="relative">
          <div className="absolute inset-x-0 -top-10 -bottom-10 bg-gradient-to-b from-[#FFF7E6] via-transparent to-[#FFF7E6] opacity-50 pointer-events-none" />
          <div className="relative">
            <CoachApplicationForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeCoach;