import { Star } from 'lucide-react';

const testimonials = [
  {
    content: "I’m taking greater ownership of my health. My coach understands my unique needs and makes every session fun and effective!",
    author: "Hubert",
    role: "Post-knee surgery client",
    image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1740485036/13_Hubert_S_sjeqjr.jpg"
  },
  {
    content: "The personalized approach and weird-but-works exercises have helped me achieve goals I never thought possible after PT.",
    author: "Jonathan",
    role: "Chronic back pain warrior",
    image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1740485036/14_Jonathon_A_cavyqd.jpg"
  },
  {
    content: "When I discovered the approach to movement that the instructor, Malorie, had; it got me interested in moving more. Doing so has been great at getting balance, gracefulness and being flexible in my movement practice and overall life!",
    author: "Leo",
    role: "Athletic recovery enthusiast",
    image: "https://res.cloudinary.com/dork9pzwh/image/upload/v1740485036/15__Leo_P_syskfe.jpg"
  }
];

const Testimonials = () => {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-base text-[#FF4D8D] font-semibold tracking-wide uppercase">
            Testimonials
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Hear From Our Wellness Weirdos
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-gradient-to-b from-[#FFF7E6] to-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col h-full"
            >
              <div className="flex items-center mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-[#FFD43B] fill-current" />
                ))}
              </div>
              <p className="text-gray-600 mb-6">{testimonial.content}</p>
              <div className="mt-auto flex items-center">
                <img
                  className="h-12 w-12 rounded-full object-cover"
                  src={testimonial.image}
                  alt={testimonial.author}
                />
                <div className="ml-4">
                  <h4 className="text-lg font-bold text-gray-900">{testimonial.author}</h4>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;