import { DollarSign, Users, Lightbulb, Heart } from 'lucide-react';
import Button from '../ui/Button';

const MovementRevolution = () => {
  const features = [
    {
      icon: DollarSign,
      title: "Flexible Schedule",
      description: "Set your own schedule to maintain a healthy and rewarding work-life balance."
    },
    {
      icon: Users,
      title: "Growing Community",
      description: "Join a network of innovative coaches and share knowledge in our exclusive community."
    },
    {
      icon: Lightbulb,
      title: "Creative Freedom",
      description: "Embrace your unique approach to movement and wellness. We celebrate what makes you weird!"
    },
    {
      icon: Heart,
      title: "Meaningful Impact",
      description: "Help clients discover sustainable wellness through your personalized coaching approach."
    }
  ];

  return (
    <section id="for-coaches" className="py-20 bg-gradient-to-b from-white to-[#FFF7E6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <p className="text-[#FF4D8D] font-semibold uppercase tracking-wide">
            FOR COACHES
          </p>
          <h2 className="mt-2 text-4xl font-bold text-gray-900">
            Join Our Movement Revolution
          </h2>
          <p className="mt-4 text-xl text-gray-600 max-w-2xl mx-auto">
            Turn your passion for movement into a thriving coaching business
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {features.map((feature, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-8">
              <div className="inline-flex items-center justify-center w-12 h-12 bg-[#FF4D8D] rounded-xl mb-6">
                <feature.icon className="h-6 w-6 text-white" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="text-center">
          <Button 
            to="/auth/callback"
            className="bg-[#FF4D8D] text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-opacity-90 transition-colors"
          >
            Apply to Become a Coach
          </Button>
        </div>
      </div>
    </section>
  );
};

export default MovementRevolution; 