import { useFormContext } from 'react-hook-form';

const CoachingStyle = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          How would you describe your coaching personality?
        </label>
        <textarea
          {...register('coachingPersonality')}
          rows={4}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.coachingPersonality ? 'border-red-300' : ''
          }`}
          placeholder="Describe your unique coaching approach and style..."
        />
        {errors.coachingPersonality && (
          <p className="mt-1 text-sm text-red-600">{errors.coachingPersonality.message as string}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          What do most clients say about you?
        </label>
        <textarea
          {...register('clientFeedback')}
          rows={4}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.clientFeedback ? 'border-red-300' : ''
          }`}
          placeholder="Share common feedback from your clients..."
        />
        {errors.clientFeedback && (
          <p className="mt-1 text-sm text-red-600">{errors.clientFeedback.message as string}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          What are the most common challenges you face with clients?
        </label>
        <textarea
          {...register('challengingClientScenarios')}
          rows={4}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.challengingClientScenarios ? 'border-red-300' : ''
          }`}
          placeholder="Describe challenging scenarios and how you handle them..."
        />
        {errors.challengingClientScenarios && (
          <p className="mt-1 text-sm text-red-600">{errors.challengingClientScenarios.message as string}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Would you like to recommend another coach? (Optional)
        </label>
        <input
          type="text"
          {...register('recommendCoach')}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF4D8D] focus:ring-[#FF4D8D] ${
            errors.recommendCoach ? 'border-red-300' : ''
          }`}
          placeholder="Name and contact information"
        />
        {errors.recommendCoach && (
          <p className="mt-1 text-sm text-red-600">{errors.recommendCoach.message as string}</p>
        )}
      </div>

      <div>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            {...register('joinFacebookGroup')}
            className={`rounded text-[#FF4D8D] focus:ring-[#FF4D8D] ${
              errors.joinFacebookGroup ? 'border-red-300' : ''
            }`}
          />
          <span className="ml-2">
            I would like to join the Wellness Weirdos Coaches Facebook Group
          </span>
        </label>
        {errors.joinFacebookGroup && (
          <p className="mt-1 text-sm text-red-600">{errors.joinFacebookGroup.message as string}</p>
        )}
      </div>
    </div>
  );
};

export default CoachingStyle;