// Add to existing constants.ts
export const TSHIRT_SIZES = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'] as const;

export const PERSONALITY_TRAITS = [
  'Empathetic',
  'Patient',
  'Motivating',
  'Creative',
  'Analytical',
  'Adaptable',
  'Energetic',
  'Calm',
  'Detail-oriented',
  'Encouraging',
  'Innovative',
  'Supportive',
] as const;

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;