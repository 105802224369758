import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { SignUp } from '@clerk/clerk-react';
import { toast } from 'react-hot-toast';

const AdminRegister = () => {
  const [registrationCode, setRegistrationCode] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  // Verify admin registration code
  const verifyRegistrationCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // This should be a secure code that only admins know
      const validCode = import.meta.env.VITE_ADMIN_REGISTRATION_CODE;
      
      if (registrationCode === validCode) {
        // Store the pending admin status in localStorage
        localStorage.setItem('pendingAdmin', 'true');
        setCodeVerified(true);
        toast.success('Registration code verified');
      } else {
        toast.error('Invalid registration code');
      }
    } catch (error) {
      console.error('Verification error:', error);
      toast.error('Verification failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Admin Registration</h2>
        
        {!codeVerified ? (
          // Step 1: Verify registration code
          <form onSubmit={verifyRegistrationCode}>
            <div className="mb-4">
              <label htmlFor="registrationCode" className="block text-sm font-medium text-gray-700 mb-1">
                Admin Registration Code
              </label>
              <input
                id="registrationCode"
                type="text"
                value={registrationCode}
                onChange={(e) => setRegistrationCode(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]"
              />
            </div>
            
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-[#FF4D8D] text-white py-2 px-4 rounded-md hover:bg-[#E6005C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4D8D] disabled:opacity-50"
            >
              {loading ? 'Verifying...' : 'Verify Code'}
            </button>
          </form>
        ) : (
          // Step 2: Clerk signup - let it handle redirection
          <SignUp 
            routing="hash"
            signInUrl="/admin/login"
            redirectUrl="/auth/callback" // Redirect to our auth callback
            appearance={{
              elements: {
                formButtonPrimary: 'bg-[#FF4D8D] hover:bg-[#E6005C]',
                formFieldInput: 'focus:border-[#FF4D8D] focus:ring-[#FF4D8D]'
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminRegister; 