import React from 'react';
import { Check } from 'lucide-react';

interface StepIndicatorProps {
  steps: string[];
  currentStep: number;
}

const StepIndicator = ({ steps, currentStep }: StepIndicatorProps) => {
  return (
    <div className="flex items-center justify-between max-w-4xl mx-auto">
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div className="flex flex-col items-center">
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center transition-all duration-200 ${
                index <= currentStep
                  ? 'bg-[#FF4D8D] text-white shadow-lg shadow-pink-200'
                  : 'bg-gray-100 text-gray-400'
              }`}
            >
              {index < currentStep ? (
                <Check className="w-6 h-6" />
              ) : (
                <span className="text-lg font-semibold">{index + 1}</span>
              )}
            </div>
            <span className={`mt-2 text-sm font-medium transition-colors duration-200 ${
              index <= currentStep ? 'text-gray-900' : 'text-gray-400'
            } hidden md:block`}>
              {step}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div className="flex-1 mx-4">
              <div
                className={`h-1 rounded-full transition-all duration-200 ${
                  index < currentStep ? 'bg-[#FF4D8D]' : 'bg-gray-100'
                }`}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepIndicator;