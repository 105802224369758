// src/layouts/MainLayout.tsx
import Header from '../components/navigation/Header';
import { Analytics } from '@vercel/analytics/react';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      {children}
      <Analytics />
    </div>
  );
};
