import { useFormContext } from 'react-hook-form';
import { WEEKDAYS } from '../../../../utils/constants';

const timeSlots = ['morning', 'afternoon', 'evening'] as const;

const Availability = () => {
  const { register } = useFormContext();

  return (
    <div className="space-y-6">
      <p className="text-gray-600">
        Select your typical availability for coaching sessions:
      </p>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2"></th>
              {timeSlots.map((slot) => (
                <th key={slot} className="px-4 py-2 text-left capitalize">
                  {slot}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {WEEKDAYS.map((day) => (
              <tr key={day} className="border-t">
                <td className="px-4 py-2 font-medium">{day}</td>
                {timeSlots.map((slot) => (
                  <td key={`${day}-${slot}`} className="px-4 py-2">
                    <input
                      type="checkbox"
                      {...register(`availability.${day}.${slot}`)}
                      className="rounded text-[#FF4D8D] focus:ring-[#FF4D8D]"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Availability;