import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { supabase } from './lib/supabase';
import { MainLayout } from './layouts/MainLayout';
import DashboardLayout from './layouts/DashboardLayout';
import Hero from './components/sections/Hero';
import HowItWorks from './components/sections/HowItWorks';
import Features from './components/sections/Features';
import { toast, Toaster } from 'react-hot-toast';
// import ForCoaches from './components/sections/ForCoaches';
import ClientJourney from './components/sections/ClientJourney';
// import FindCoach from './components/sections/FindCoach';
import Testimonials from './components/sections/Testimonials';
import Pricing from './components/sections/Pricing';
import BecomeCoach from './pages/BecomeCoach';
import ComingSoon from './pages/ComingSoon';
import Dashboard from './pages/coach/Dashboard';
import EditApplication from './pages/coach/EditApplication';
import ReferralForm from './pages/coach/ReferralForm';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { AuthCallback } from './auth/AuthCallback';
// import { Toaster } from 'react-hot-toast';
import WhyWellnessGap from './components/sections/WhyWellnessGap';
// import JourneySteps from './components/sections/JourneySteps';
import Benefits from './components/sections/Benefits';
// import WellnessStrength from './components/sections/WellnessStrength';
// import EverythingYouNeed from './components/sections/EverythingYouNeed';
// import RealStories from './components/sections/RealStories';
// import RealStressResults from './components/sections/RealStressResults';
import PerfectMatch from './components/sections/PerfectMatch';
import MovementRevolution from './components/sections/MovementRevolution';

// Admin Dashboard
import AdminDashboard from './pages/admin/Dashboard';
// import AdminCoaches from './pages/admin/Coaches';
// import AdminClients from './pages/admin/Clients';
// import AdminSupport from './pages/admin/Support';
// import AdminSettings from './pages/admin/Settings';
import CoachApplications from './pages/admin/CoachApplications';
import ReviewApplication from './pages/admin/ReviewApplication';
// import ClientMatching from './pages/admin/ClientMatching';
// import MessageMonitoring from './pages/admin/MessageMonitoring';
// import HelpDesk from './pages/admin/HelpDesk';
import AdminLogin from './pages/admin/Login';
import AdminRegister from './pages/admin/Register';
import SubscribersAndReferrals from './pages/admin/SubscribersAndReferrals';

// Create a protected route for admin
const AdminProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded, isSignedIn, user } = useUser();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!isLoaded) return;
      
      if (!isSignedIn || !user) {
        navigate('/admin/login');
        return;
      }
      
      try {
        const userEmail = user.primaryEmailAddress?.emailAddress;
        
        if (!userEmail) {
          navigate('/admin/login');
          return;
        }
        
        // Check if user has admin role in Supabase
        const { data: userData } = await supabase
          .from('User')
          .select('id, role')
          .eq('clerkUserId', user.id)
          .single();
          
        if (userData?.role !== 'ADMIN') {
          toast.error('Unauthorized: Admin access only');
          navigate('/admin/login');
          return;
        }
        
        setIsAdmin(true);
      } catch (error) {
        console.error('Error checking admin status:', error);
        toast.error('Authentication error');
        navigate('/admin/login');
      }
    };
    
    checkAdminStatus();
  }, [isLoaded, isSignedIn, navigate, user]);
  
  if (!isLoaded || isAdmin === null) {
    return <div>Loading...</div>;
  }
  
  return <>{children}</>;
};

function App() {
  return (
    <>
      <MainLayout>
        <Routes>
          <Route path="/" element={
            <main>
              <Hero />
              <WhyWellnessGap />
              <HowItWorks />
              <ClientJourney />
              <Features />
              <Benefits />
              <Testimonials />
              <Pricing />
              <PerfectMatch />
              <MovementRevolution />
              
              {/* <JourneySteps />
              
              <EverythingYouNeed />
              <RealStories />
              <RealStressResults />
              
              
              
              
              
              <ForCoaches />
              
              <FindCoach /> */}
              
             
            </main>
          } />
          <Route path="/become-coach" element={
            <ProtectedRoute>
              <BecomeCoach />
            </ProtectedRoute>
          } />
          <Route path="/find-coach" element={<ComingSoon />} />
          <Route path="/coach/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/coach/edit-application" element={
            <ProtectedRoute>
              <EditApplication />
            </ProtectedRoute>
          } />
          <Route path="/coach/refer/:type" element={
            <ProtectedRoute>
              <ReferralForm />
            </ProtectedRoute>
          } />
          <Route path="/auth/callback" element={
             <ProtectedRoute>
              <AuthCallback />
             </ProtectedRoute>
            } />

            {/* Admin Authentication */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/register" element={<AdminRegister />} />

          {/* Admin Dashboard - now protected */}
          <Route element={<AdminProtectedRoute><DashboardLayout /></AdminProtectedRoute>}>
            <Route path="/admin" element={<AdminDashboard />} />
            {/* <Route path="/admin/coaches" element={<AdminCoaches />} />
            <Route path="/admin/clients" element={<AdminClients />} />
            <Route path="/admin/support" element={<AdminSupport />} />
            <Route path="/admin/settings" element={<AdminSettings />} /> */}
            <Route path="/admin/applications" element={<CoachApplications />} />
            <Route path="/admin/applications/:id" element={<ReviewApplication />} />
            <Route path="/admin/email-management" element={<SubscribersAndReferrals />} />
            {/* <Route path="/admin/matching" element={<ClientMatching />} />
            <Route path="/admin/messages" element={<MessageMonitoring />} />
            <Route path="/admin/helpdesk" element={<HelpDesk />} /> */}
          </Route>
        </Routes>
      </MainLayout>
      <Toaster position="top-center" />
    </>
  );
}

export default App;
