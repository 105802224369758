// import React from 'react';
import { Users, Mail } from 'lucide-react';
import Button from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold">Admin Dashboard</h1>
          <p className="text-gray-600">Wellness Weirdos Administration</p>
        </div>
      </div>

      {/* Dashboard Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Coach Applications Card */}
        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="p-4 bg-primary/10 rounded-full">
              <Users className="w-12 h-12 text-primary" />
            </div>
            
            <div>
              <h2 className="text-xl font-bold mb-2">Coach Applications</h2>
              <p className="text-gray-600 mb-6">
                Review and manage coach applications
              </p>
              
              <Button 
                onClick={() => navigate('/admin/applications')}
                className="px-8 w-full sm:w-auto"
              >
                View Coach Applications
              </Button>
            </div>
          </div>
        </div>

        {/* Email Management Card - Updated to use secondary color */}
        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="p-4 bg-secondary/10 rounded-full">
              <Mail className="w-12 h-12 text-secondary" />
            </div>
            
            <div>
              <h2 className="text-xl font-bold mb-2">Email Management</h2>
              <p className="text-gray-600 mb-6">
                View subscriptions and referrals
              </p>
              
              <Button 
                onClick={() => navigate('/admin/email-management')}
                className="px-8 w-full sm:w-auto bg-secondary hover:bg-secondary/90"
              >
                Manage Emails
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;