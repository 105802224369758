import { Menu, X } from 'lucide-react';
import Logo from '../ui/Logo';
import Button from '../ui/Button';
import { useState } from 'react';
import { HashLink } from "react-router-hash-link";
import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';

// const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
// const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  
  // Check if we're on an admin page
  const isAdminPage = location.pathname.startsWith('/admin');

  const handleSignInClick = async () => {
    // The actual redirect will be handled by AuthCallback
    // This is just to store the intent
    sessionStorage.setItem('redirectAfterAuth', '/coach/dashboard');
  };

  return (
    <header className="bg-white shadow-lg">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          {/* Logo Section - conditionally rendered */}
          <div className="flex items-center">
            {!isAdminPage && <Logo />}
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <HashLink to="/#how-it-works" smooth className="text-gray-600 hover:text-[#FF4D8D] font-medium">
              How It Works
            </HashLink>
            <HashLink to="/#for-clients" smooth className="text-gray-600 hover:text-[#FF4D8D] font-medium">
              For Clients
            </HashLink>
            <HashLink to="/#for-coaches" smooth className="text-gray-600 hover:text-[#FF4D8D] font-medium">
              For Coaches
            </HashLink>            
            {/* Clerk Buttons */}
            <SignedOut>
              <SignInButton mode="modal" >
                <Button onClick={handleSignInClick}>Sign In</Button>
              </SignInButton>
            </SignedOut>
            <SignedIn>
              <UserButton />
            </SignedIn>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-900"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <HashLink to="/#how-it-works" smooth className="block px-3 py-2 text-gray-600 hover:text-[#FF4D8D] font-medium">
                How It Works
              </HashLink>
              <HashLink to="/#for-clients" smooth className="block px-3 py-2 text-gray-600 hover:text-[#FF4D8D] font-medium">
                For Clients
              </HashLink>
              <HashLink to="/#for-coaches" smooth className="block px-3 py-2 text-gray-600 hover:text-[#FF4D8D] font-medium">
                For Coaches
              </HashLink>    
              <div className="px-3 py-2">
                <SignedOut>
                  <SignInButton mode="modal">
                    <Button className="w-full justify-center" onClick={handleSignInClick}>Sign In</Button>
                  </SignInButton>
                </SignedOut>
                <SignedIn>
                  <UserButton />
                </SignedIn>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
