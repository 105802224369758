// src/pages/auth/AuthCallback.tsx
// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '@clerk/clerk-react';
import { supabase } from '../lib/supabase'; 

// interface TokenResponse {
//   access_token: string;
//   refresh_token: string;
//   expires_in: number;
// }

// interface UserProfile {// src/pages/auth/AuthCallback.tsx
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';
// import { createClient } from '@supabase/supabase-js';
import { toast } from 'react-hot-toast';

// Create Supabase client
// const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
// const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

// Function to generate a CUID-like ID
const generateId = () => {
  return 'cuid_' + Date.now().toString(36) + Math.random().toString(36).substring(2, 9);
};

// interface TokenResponse {
//   access_token: string;
//   refresh_token: string;
//   expires_in: number;
//   token_type: string;
// }

// interface UserProfile {
//   id: string;
//   email: string;
//   name?: string;
// }

export const AuthCallback = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { isSignedIn, userId } = useAuth();
  const { user } = useUser();
  
  useEffect(() => {
    const checkUserStatus = async () => {
      if (!isSignedIn || !userId || !user) return;

      try {
        // First, check if user exists in User table regardless of flow
        let { data: userData, error: userCheckError } = await supabase
          .from('User')
          .select('id, role')
          .eq('clerkUserId', userId)
          .single();
          
        // Check if this is a pending admin registration
        const isPendingAdmin = localStorage.getItem('pendingAdmin') === 'true';
        const userEmail = user.primaryEmailAddress?.emailAddress;
        
        // If user doesn't exist in database, create them
        if (userCheckError || !userData) {
          console.log('User not found in database, creating new user record');
          
          // Generate a unique ID for the user
          const newUserId = generateId();
          
          // Determine the role based on pendingAdmin flag
          const role = isPendingAdmin ? 'ADMIN' : 'CLIENT';
          
          // Create user record
          const { data: newUserData, error: createUserError } = await supabase
            .from('User')
            .insert([{
              id: newUserId,
              clerkUserId: userId,
              email: userEmail,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              role: role
            }])
            .select();
          
          if (createUserError) {
            console.error('Error creating user:', createUserError);
            // toast.error('User creation failed. Please contact support.');
            navigate('/');
            return;
          }
          
          userData = newUserData[0];
          console.log('User created successfully:', userData);
        } else if (userData && isPendingAdmin && userData.role !== 'ADMIN') {
          // If user exists but is not an admin and pendingAdmin is true, update role
          console.log('Updating existing user to admin role');
          
          const { error: updateRoleError } = await supabase
            .from('User')
            .update({ 
              role: 'ADMIN',
              updatedAt: new Date().toISOString()
            })
            .eq('id', userData.id);
          
          if (updateRoleError) {
            console.error('Error updating user role:', updateRoleError);
          }
        }
        
        // Now handle specific flows based on the user role or pending admin status
        if (userData && isPendingAdmin) {
          console.log('Processing pending admin registration');
          
          // Check if admin record already exists
          const { data: existingAdmin } = await supabase
            .from('Admin')
            .select('id')
            .eq('userId', userData.id)
            .single();
            
          if (!existingAdmin) {
            // Generate a unique ID for the admin
            const newAdminId = generateId();
            
            // Create admin record
            const { data: adminData, error: adminError } = await supabase
              .from('Admin')
              .insert([{ 
                id: newAdminId,
                userId: userData.id,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                permissions: JSON.stringify({ fullAccess: true })
              }])
              .select();
            
            if (adminError) {
              console.error('Error registering admin:', adminError);
              toast.error('Admin registration failed. Please contact support.');
              localStorage.removeItem('pendingAdmin');
              navigate('/admin/login');
              return;
            }
            
            console.log('Admin registration successful:', adminData);
          }
          
          // Clear the pending status
          localStorage.removeItem('pendingAdmin');
          toast.success('Admin registration successful!');
          navigate('/admin');
          return;
        }
        
        // Alternative approach
        if (userData) {
          if (userData.role === 'ADMIN') {
            console.log('User is an admin, redirecting to admin dashboard');
            navigate('/admin');
            return;
          }
        }

        // If not admin, continue with regular user flow
        // Check if coach profile exists
        const { data: coachData, error: coachError } = await supabase
          .from('Coach')
          .select('id')
          .eq('userId', userData?.id || '')
          .single();

        if (!coachError && coachData) {
          // Coach profile exists, redirect to dashboard
          navigate('/coach/dashboard');
        } else {
          // No coach profile, redirect to onboarding
          navigate('/become-coach');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred during authentication');
        navigate('/become-coach');
      }
    };

    checkUserStatus();
  }, [isSignedIn, userId, navigate, user]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Redirecting...</div>;
};
//   id: string;
//   email: string;
//   first_name: string;
//   last_name: string;
// }
