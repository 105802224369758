import { useEffect, useState } from 'react';
import { Mail, Edit, Share2, Video } from 'lucide-react';
import Button from '../../components/ui/Button';
import { useUser } from '@clerk/clerk-react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';

// Create Supabase client
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Use enum values that match the database
type ApplicationStatus = 'PENDING' | 'APPROVED' | 'REJECTED';

interface DashboardProps {
  status?: ApplicationStatus;
}

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string;
  applicationStatus: ApplicationStatus;
}

const Dashboard: React.FC<DashboardProps> = ({ status: defaultStatus = 'PENDING' }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!user) return;

      try {
        // First, get user data from User table using clerkUserId
        const { data: userData, error: userError } = await supabase
          .from('User')
          .select('id, email')
          .eq('clerkUserId', user.id)
          .single();

        if (userError) {
          console.error('Error fetching user:', userError);
          setError('Error fetching user data');
          setLoading(false);
          return;
        }

        if (!userData) {
          console.log('No user found with this Clerk ID');
          setError('No user found with your credentials');
          setLoading(false);
          return;
        }

        console.log('User data:', userData);

        // Then, get coach data using the user's id
        const { data: coachData, error: coachError } = await supabase
          .from('Coach')
          .select('firstName, lastName, profileImage, applicationStatus')
          .eq('userId', userData.id)
          .single();

        if (coachError && coachError.code !== 'PGRST116') {
          // PGRST116 is "no rows returned" - this is fine if the user isn't a coach yet
          console.error('Error fetching coach:', coachError);
          setError('Error fetching coach data');
          setLoading(false);
          return;
        }

        if (coachData) {
          console.log('Coach data:', coachData);
          setProfileData({
            firstName: coachData.firstName,
            lastName: coachData.lastName,
            email: userData.email,
            profileImage: coachData.profileImage,
            applicationStatus: coachData.applicationStatus || defaultStatus
          });
        } else {
          // No coach profile yet, use clerk data
          setProfileData({
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.emailAddresses[0].emailAddress,
            profileImage: user.imageUrl || 'https://via.placeholder.com/150',
            applicationStatus: defaultStatus
          });
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user, defaultStatus]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-lg text-center">
          <h2 className="text-xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <Button onClick={() => window.location.reload()}>Try Again</Button>
        </div>
      </div>
    );
  }

  if (!user || !profileData) {
    return <div>Loading...</div>;
  }

  const profile = {
    firstName: profileData.firstName || user.firstName || '.',
    lastName: profileData.lastName || user.lastName || '.',
    email: profileData.email || user.emailAddresses[0].emailAddress,
    imageUrl: profileData.profileImage || user.imageUrl || 'https://via.placeholder.com/150',
  };

  const renderStatusContent = () => {
    // Use the status from the database instead of the prop
    const status = profileData?.applicationStatus;
    
    switch (status) {
      case 'PENDING':
        return (
          <div className="bg-background-light p-8 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">Application Status: Pending</h2>
            <p className="text-gray-600 mb-6">
              Your application is currently under review. While you wait, check out our welcome video!
            </p>
            <div className="aspect-video bg-gray-100 rounded-lg mb-6">
              {/* Video placeholder */}
              <div className="h-full flex items-center justify-center">
              <video
                className="w-full h-full object-cover"
                controls
                poster="https://res.cloudinary.com/dork9pzwh/image/upload/v1740485319/vlcsnap-2025-02-25-13h07m34s240_xjlza4.png" // Optional: Add a thumbnail
              >
                <source src="https://res.cloudinary.com/dork9pzwh/video/upload/v1740485047/Video_vshndw.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              </div>
            </div>
            <Button 
              variant="secondary" 
              onClick={() => navigate('/coach/edit-application')} 
              className="mr-4"
            >
              <Edit className="w-4 h-4 mr-2" />
              Edit Application
            </Button>
          </div>
        );

      case 'REJECTED':
        return (
          <div className="bg-red-50 p-8 rounded-xl">
            <h2 className="text-2xl font-bold mb-4 text-red-800">Application Status: Not Approved</h2>
            <p className="text-gray-700 mb-4">
              Thank you for the time and care you put into your application. Unfortunately, our current offerings don't align with your expertise and experience.
            </p>
            <p className="text-gray-700">
              If you would like feedback on your application, please email{' '}
              <a href="mailto:malorie@wellnessweirdos.com" className="text-primary hover:underline">
                malorie@wellnessweirdos.com
              </a>
              {' '}with the subject line: [{profile.firstName} {profile.lastName}] Feedback
            </p>
          </div>
        );

      case 'APPROVED':
        return (
          
          <div className="bg-green-50 p-8 rounded-xl">
                    
            <h2 className="text-2xl font-bold mb-4 text-green-800">
              Congratulations! You're Approved!
            </h2>
            <p className="text-gray-700 mb-4">
              Welcome to the Wellness Weirdos coaching team! Here's what to do next:
            </p>
            
            {/* Welcome video for approved coaches */}
            <div className="aspect-video bg-gray-100 rounded-lg mb-6">
              <div className="h-full flex items-center justify-center">
              
                <video
                  className="w-full h-full object-cover rounded-lg"
                  controls
                  poster="https://res.cloudinary.com/dork9pzwh/image/upload/v1740485319/vlcsnap-2025-02-25-13h07m34s240_xjlza4.png"
                >
                  <source src="https://res.cloudinary.com/dork9pzwh/video/upload/v1740485047/Video_vshndw.mov" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            
            <div className="space-y-4 mb-8">
              <div className="flex items-center">
                <Video className="w-6 h-6 text-primary mr-3" />
                <span>Watch the welcome video above</span>
              </div>
              {/* <div className="flex items-center">
                <Edit className="w-6 h-6 text-primary mr-3" />
                <span>Submit required documents</span>
              </div> */}
              <div className="flex items-center">
                <Share2 className="w-6 h-6 text-primary mr-3" />
                <span>Review tips and tricks for virtual coaching</span>
              </div>
            </div>
            <div className="flex space-x-4">
              {/* <Button>Get Started</Button> */}
              <a 
                href="https://drive.google.com/file/d/1VPXOnFHlL-md09yjnO7PMfvmVyGlG7F0/view?usp=sharing"
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Button variant="secondary">View Resource</Button>
              </a>
            </div>
          </div>
        );
        
      default:
        return (
          <div className="bg-background-light p-8 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">Welcome to Wellness Weirdos</h2>
            <p className="text-gray-600 mb-6">
              To get started as a coach, please complete your application.
            </p>
            <Button 
              onClick={() => navigate('/become-coach')}
            >
              Start Application
            </Button>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background-light to-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Centered Dashboard Heading */}
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold text-gray-900">Coach Dashboard</h1>
          {/* <p className="text-gray-600">Welcome back, {profile.firstName}!</p> */}
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Profile Section */}
          <div className="lg:col-span-1">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="text-center mb-6">
                <div className="w-32 h-32 mx-auto bg-gray-200 rounded-full mb-4">
                
                <img src={profile.imageUrl} alt="" className="w-32 h-32 mx-auto rounded-full mb-4" />
               
                </div>
                <h2 className="text-xl font-bold">
                  {profile.firstName} {profile.lastName}
                </h2>
                <p className="text-gray-600">{profile.email}</p>
              </div>
              {/* <Button 
                variant="secondary" 
                className="w-full"
                onClick={() => navigate('/coach/edit-application')}
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit Profile
              </Button> */}
            </div>

            {/* Referral Section */}
            <div className="bg-white p-8 rounded-xl shadow-lg mt-8">
              <h3 className="text-lg font-bold mb-4">Share Wellness Weirdos</h3>
              <div className="space-y-4">
                <Button 
                  variant="secondary" 
                  className="w-full"
                  onClick={() => navigate('/coach/refer/coach')}
                >
                  <Mail className="w-4 h-4 mr-2" />
                  Refer a Coach
                </Button>
                <Button 
                  variant="secondary" 
                  className="w-full"
                  onClick={() => navigate('/coach/refer/client')}
                >
                  <Mail className="w-4 h-4 mr-2" />
                  Refer a Client
                </Button>
              </div>
            </div>
          </div>

          {/* Status Content */}
          <div className="lg:col-span-2">
            {renderStatusContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;