import { Link } from "react-router-dom";
import { Dumbbell } from "lucide-react";

const Logo = () => {
  return (
    <Link to="/" className="flex items-center gap-2">
      <Dumbbell className="h-8 w-8 text-primary animate-float" />
      <span className="font-heading font-extrabold text-2xl tracking-tight">
        <span className="text-primary">Wellness</span>
        <span className="text-secondary">Weirdos</span>
      </span>
    </Link>
  );
};

export default Logo;
